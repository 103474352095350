import React, { useEffect, useState } from 'react';
import {
  AppBar, Toolbar, Typography, Avatar, IconButton, Menu, MenuItem,
  Tooltip, Divider, useMediaQuery, Box,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Estilização do AppBar com gradiente e menor altura
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundImage: 'linear-gradient(90deg, #1E4A7B 0%, #1C5B90 100%)',
  height: 60, // Altura do AppBar reduzida
  justifyContent: 'center',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

export default function Header({ onToggleSidebar }) {
  const [user, setUser] = useState({ nome: '', email: '' });
  const [currentTime, setCurrentTime] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/me.php`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        handleLogout();
      }
    } catch (error) {
      handleLogout();
    }
  };

  const updateTime = () => {
    const date = new Date().toLocaleTimeString('pt-BR', { timeZone: 'America/Cuiaba' });
    setCurrentTime(date);
  };

  useEffect(() => {
    fetchUserData();
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser({});
    navigate('/');
  };

  const timeStyle = {
    color: '#fff',
    fontSize: '1rem', // Reduz o tamanho da fonte do relógio
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar sx={{ minHeight: '60px', padding: '0 16px' }}>
        <IconButton edge="start" onClick={onToggleSidebar} sx={{ mr: 2 }}>
          <MenuIcon sx={{ color: '#fff', fontSize: 28 }} />
        </IconButton>

        <Typography variant="h6" sx={{ flexGrow: 1, color: '#fff', fontSize: '1.2rem' }}>
          {user.nome || 'Usuário'}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginRight: 2 }}>
          <AccessTimeIcon sx={{ color: '#fff', fontSize: 22 }} />
          <Typography variant="subtitle1" sx={timeStyle}>
            {currentTime}
          </Typography>
        </Box>

        <Tooltip title={`Usuário: ${user.nome} (${user.email})`}>
          <IconButton onClick={handleMenuOpen} sx={{ p: 0, marginRight: 1 }}>
            <Avatar sx={{ width: 36, height: 36 }}>
              {user.nome.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>

        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem disabled>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle1">{user.nome}</Typography>
              <Typography variant="body2" color="textSecondary">
                {user.email}
              </Typography>
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => navigate('/meu-perfil')}>
            <AccountCircleIcon sx={{ mr: 1 }} /> Perfil
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 1 }} /> Sair
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}
