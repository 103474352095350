import React, { useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  useMediaQuery,
  Container,
  Divider,
} from '@mui/material';
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  ShoppingCart as ShoppingCartIcon,
  Build as BuildIcon,
  Work as WorkIcon,
  ArrowForwardIos as ArrowIcon,
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const cadastroModules = [
  { title: 'Clientes', icon: <PersonIcon />, link: '/clientes' },
  { title: 'Fornecedores', icon: <BusinessIcon />, link: '/fornecedores' },
  { title: 'Funcionários', icon: <GroupIcon />, link: '/funcionarios' },
  { title: 'Produtos', icon: <ShoppingCartIcon />, link: '/produtos' },
  { title: 'Projetos', icon: <BuildIcon />, link: '/projetos' },
  { title: 'Serviços', icon: <WorkIcon />, link: '/servicos' },
];

export default function Cadastros() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: { main: '#1E4A7B' },
          secondary: { main: '#546E7A' },
          background: { default: '#F3F6F9', paper: '#FFFFFF' },
        },
        typography: { fontFamily: 'Poppins, sans-serif', fontSize: 14 },
      }),
    []
  );

  const [searchTerm, setSearchTerm] = useState('');
  const filteredModules = cadastroModules.filter((module) =>
    module.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: isSmallScreen ? 2 : 4,
          minHeight: '100vh',
          background: theme.palette.background.default,
        }}
      >
        {/* Header */}
        <Container maxWidth="lg" sx={{ textAlign: 'center', marginBottom: 4 }}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Cadastros
            </Typography>
            <Typography variant="subtitle1" sx={{ color: theme.palette.secondary.main, marginTop: 1 }}>
              Gerencie todos os cadastros essenciais para o seu negócio.
            </Typography>
          </motion.div>
        </Container>

        {/* Campo de Busca */}
        <Container maxWidth="lg" sx={{ marginBottom: 4 }}>
          <TextField
            label="Buscar Cadastro"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Container>

        <Divider sx={{ marginY: 4 }} />

        {/* Lista de Cadastros em 2 Colunas */}
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {filteredModules.map((module, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.5 }}
                >
                  <ListItem
                    button
                    component={Link}
                    to={module.link}
                    sx={{
                      borderRadius: 2,
                      boxShadow: 1,
                      marginBottom: 2,
                      '&:hover': {
                        boxShadow: 3,
                        transform: 'translateX(5px)',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{module.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={module.title}
                      primaryTypographyProps={{ fontWeight: 500 }}
                    />
                    <ArrowIcon />
                  </ListItem>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
