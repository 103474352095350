import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    primary: { main: '#2c3e50' },
    secondary: { main: '#f39c12' },
    background: { default: '#ecf0f1' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontWeight: 700,
      color: '#34495e',
    },
  },
});

export default function MeuPerfil() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [dataCriacao, setDataCriacao] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchPerfil = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/perfil.php`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setNome(data.nome);
          setEmail(data.email);
          setLogin(data.login);
          setDataCriacao(data.data_criacao);
        } else {
          const error = await response.json();
          setSnackbarMessage(error.message || 'Erro ao carregar perfil');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        }
      } catch (err) {
        setSnackbarMessage('Erro ao conectar com o servidor');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    };

    fetchPerfil();
  }, []);

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleUpdatePassword = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/perfil.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ novaSenha }),
      });

      if (response.ok) {
        setSnackbarMessage('Senha atualizada com sucesso!');
        setSnackbarSeverity('success');
      } else {
        const error = await response.json();
        setSnackbarMessage(error.message || 'Erro ao atualizar senha');
        setSnackbarSeverity('error');
      }
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage('Erro ao conectar com o servidor');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="1200px" sx={{ mt: 5 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
          <Typography component="h1" variant="h6" textAlign="center" sx={{ mb: 2 }}>
            Meu Perfil
          </Typography>
          <Box>
            <TextField
              margin="normal"
              fullWidth
              label="Nome"
              value={nome}
              InputProps={{ readOnly: true }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="E-mail"
              value={email}
              InputProps={{ readOnly: true }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Login"
              value={login}
              InputProps={{ readOnly: true }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Data de Criação"
              value={dataCriacao}
              InputProps={{ readOnly: true }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Nova Senha"
              type="password"
              value={novaSenha}
              onChange={(e) => setNovaSenha(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleUpdatePassword}
            >
              Atualizar Senha
            </Button>
          </Box>
        </Paper>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}
