import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Grid, Typography, Paper, Modal,
  Snackbar, Alert, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, MenuItem, Stack, Breadcrumbs, Link, Autocomplete, Toolbar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { NumericFormat } from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'; // Importa axios para a requisição à API
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer } from 'recharts';

export default function ContasAPagar() {
  const [contas, setContas] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [categorias, setCategorias] = useState([]); // Estado para armazenar as categorias
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [valor, setValor] = useState('');
  const [dataVencimento, setDataVencimento] = useState(null);
  const [dataPagamento, setDataPagamento] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [historico, setHistorico] = useState('');
  const [status, setStatus] = useState('pendente');
  const [selectedFornecedor, setSelectedFornecedor] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [centrosDeCusto, setCentrosDeCusto] = useState([]); // Estado para armazenar os centros de custo
  const [selectedCentroCusto, setSelectedCentroCusto] = useState(null); // Define o estado para Centro de Custo
  const [contasBancarias, setContasBancarias] = useState([]); // Estado para armazenar as contas bancárias
  const [resumo, setResumo] = useState({ receitas: 0, despesas: 0, total: 0 });

  const [graficoData, setGraficoData] = useState([]);



  const [selectedContaEmpresa, setSelectedContaEmpresa] = useState('');
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState('');
const [numeroPedido, setNumeroPedido] = useState('');
const [parcelado, setParcelado] = useState('nao'); // 'nao' para não parcelado, 'sim' para parcelado
const [quantidadeParcelas, setQuantidadeParcelas] = useState('');
// Coloque isso no início do componente, junto com os outros useState
const todasAsColunas = [
  'id', 'fornecedor_id', 'categoria_id', 'valor', 'data_vencimento',
  'status', 'historico', 'data_pagamento', 'data_emissao',
  'numero_nota_fiscal', 'numero_pedido', 'parcelado', 'quantidade_parcelas'
];

const [colunasVisiveis, setColunasVisiveis] = useState(['id', 'fornecedor_id', 'valor', 'data_vencimento']);

const fetchColunas = () => {
  setColunasVisiveis(['Data', 'Fornecedor', 'Valor']); // Defina colunas visíveis padrão
};

// URL da API para categorias de transações
const categoriasApiUrl = 'https://athixdev.xyz/aabb/categorias_transacoes.php';
const fornecedoresApiUrl = 'https://athixdev.xyz/aabb/fornecedores.php';
const centrosDeCustoApiUrl = 'https://athixdev.xyz/aabb/centro_de_custo.php'; // URL para Centro de Custo
const contasBancariasApiUrl = 'https://athixdev.xyz/aabb/contas_bancarias.php'; // API para buscar contas bancárias

const handleToggleColuna = (coluna) => {
  setColunasVisiveis(prev => (
      prev.includes(coluna) ? prev.filter(c => c !== coluna) : [...prev, coluna]
  ));
};

const navigate = useNavigate();

const renderCheckboxes = () => (
  <div>
      {todasAsColunas.map(coluna => (
          <div key={coluna}>
              <Checkbox
                  checked={colunasVisiveis.includes(coluna)}
                  onChange={() => handleToggleColuna(coluna)}
              />
              <span>{coluna}</span>
          </div>
      ))}
  </div>
);
const fetchContas = async () => {
  try {
    const response = await axios.get('https://athixdev.xyz/aabb/contas_pagar.php');

    // Formatando os dados recebidos
    const formattedData = response.data.map((item) => ({
      id: item.id,
      Fornecedor: item.fornecedor_nome || 'N/A', // Nome do fornecedor
      Categoria: item.categoria_nome || 'N/A', // Nome da categoria
      Valor: item.valor ? `R$ ${parseFloat(item.valor).toFixed(2)}` : 'N/A',
      DataVencimento: item.data_vencimento ? displayDate(item.data_vencimento) : 'N/A',
      DataPagamento: item.data_pagamento ? displayDate(item.data_pagamento) : 'N/A',
      DataEmissao: item.data_emissao ? displayDate(item.data_emissao) : 'N/A',
      Status: item.status || 'N/A',
      Histórico: item.historico || 'N/A',
      NumeroNotaFiscal: item.numero_nota_fiscal || 'N/A',
      NumeroPedido: item.numero_pedido || 'N/A',
      Parcelado: item.parcelado === 'sim' ? 'Sim' : 'Não',
      QuantidadeParcelas: item.quantidade_parcelas || 'N/A',
    }));

    setContas(formattedData);
    console.log("Dados formatados:", formattedData);
  } catch (error) {
    console.error("Erro ao buscar contas a pagar:", error);
  }
};


useEffect(() => {
  console.log("Contas carregadas:", contas);
}, [contas]);




const [editingId, setEditingId] = useState(null);

const handleSave = async () => {
  const payload = {
    fornecedor_id: selectedFornecedor?.id || null,
    categoria_id: selectedCategoria?.id || null,
    valor: parseFloat(valor) || 0,
    data_vencimento: dataVencimento ? dataVencimento.toISOString().split('T')[0] : null,
  data_pagamento: dataPagamento ? dataPagamento.toISOString().split('T')[0] : null,
  data_emissao: dataEmissao ? dataEmissao.toISOString().split('T')[0] : null,
    numero_nota_fiscal: numeroNotaFiscal || null,
    numero_pedido: numeroPedido || null,
    parcelado,
    quantidade_parcelas: parcelado === 'sim' ? parseInt(quantidadeParcelas, 10) : null,
    status,
    historico,
  };

  try {
    if (editingId) {
      await axios.put(`https://athixdev.xyz/aabb/contas_pagar.php`, {
        ...payload,
        id: editingId,
      });
      setSnackbarMessage('Conta atualizada com sucesso!');
    } else {
      await axios.post(`https://athixdev.xyz/aabb/contas_pagar.php`, payload);
      setSnackbarMessage('Conta adicionada com sucesso!');
    }

    setSnackbarOpen(true);
    setModalOpen(false);
    fetchContas();
    resetForm();
  } catch (error) {
    console.error("Erro ao salvar conta:", error);
    setSnackbarMessage('Erro ao salvar conta.');
    setSnackbarOpen(true);
  }
};



const formatDateToBR = (date) => {
  if (!date) return null;
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};


const displayDate = (dateStr) => {
  if (!dateStr) return 'N/A';
  const [year, month, day] = dateStr.split('-');
  return `${day}/${month}/${year}`;
};


// Função para resetar o formulário
const resetForm = () => {
  setEditingId(null);
  setSelectedFornecedor(null);
  setSelectedCategoria(null);
  setValor('');
  setDataVencimento(null);
  setDataPagamento(null);
  setDataEmissao(null);
  setHistorico('');
  setStatus('pendente');
  setNumeroNotaFiscal('');
  setNumeroPedido('');
  setParcelado('nao');
  setQuantidadeParcelas('');
};




  
  // Função para buscar as categorias da API
  const fetchCategorias = async () => {
    try {
      const response = await axios.get(categoriasApiUrl);
      setCategorias(response.data); // Armazena as categorias no estado
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  const fetchContasBancarias = async () => {
    try {
      const response = await axios.get('https://athixdev.xyz/aabb/contas_bancarias.php');
      setContasBancarias(response.data); // Armazena as contas no estado
    } catch (error) {
      console.error("Erro ao buscar contas bancárias:", error);
    }
  };

  const fetchCentrosDeCusto = async () => {
    try {
      const response = await axios.get(centrosDeCustoApiUrl);
      setCentrosDeCusto(response.data);
    } catch (error) {
      console.error("Erro ao buscar centros de custo:", error);
    }
  };  
 

  const handleDelete = async (id) => {
    try {
        await axios.delete('https://athixdev.xyz/aabb/contas_pagar.php', { data: { id } });
        setSnackbarMessage('Conta removida com sucesso!');
        setSnackbarOpen(true);
        fetchContas();
    } catch (error) {
        console.error("Erro ao excluir conta:", error);
    }
};

const handleEdit = (conta) => {
  setEditingId(conta.id);

  // Buscar o fornecedor e categoria selecionados
  const fornecedorSelecionado = fornecedores.find(f => f.nome_razao_social === conta.Fornecedor);
  setSelectedFornecedor(fornecedorSelecionado || null);

  const categoriaSelecionada = categorias.find(c => c.nome === conta.Categoria);
  setSelectedCategoria(categoriaSelecionada || null);

  setValor(conta.Valor !== 'N/A' ? conta.Valor.replace('R$', '').trim() : '');
  setDataVencimento(conta.DataVencimento !== 'N/A' ? new Date(conta.DataVencimento) : null);
  setDataPagamento(conta.DataPagamento !== 'N/A' ? new Date(conta.DataPagamento) : null);
  setDataEmissao(conta.DataEmissao !== 'N/A' ? new Date(conta.DataEmissao) : null);
  setHistorico(conta.Histórico !== 'N/A' ? conta.Histórico : '');
  setStatus(conta.Status);
  setNumeroNotaFiscal(conta.NumeroNotaFiscal !== 'N/A' ? conta.NumeroNotaFiscal : '');
  setNumeroPedido(conta.NumeroPedido !== 'N/A' ? conta.NumeroPedido : '');
  setParcelado(conta.Parcelado === 'Sim' ? 'sim' : 'nao');
  setQuantidadeParcelas(conta.QuantidadeParcelas !== 'N/A' ? conta.QuantidadeParcelas : '');
  
  setModalOpen(true);
};



useEffect(() => {
  fetchColunas();
  fetchContas();
  fetchFornecedores();
  fetchCategorias();
  fetchCentrosDeCusto();
  fetchContasBancarias();
}, []);
useEffect(() => {
  console.log("Estado `contas` atualizado:", contas);
}, [contas]);


   // Função para buscar fornecedores da API
   const fetchFornecedores = async () => {
    try {
      const response = await axios.get(fornecedoresApiUrl);
      setFornecedores(response.data);
      console.log("Fornecedores recebidos:", response.data); // Log para verificar os dados
    } catch (error) {
      console.error("Erro ao buscar fornecedores:", error);
    }
  };
  

  const data = [
    { name: 'Receitas', value: 86423.6 },
    { name: 'Despesas', value: 32587.2 },
  ];


  useEffect(() => {
    const fetchResumo = async () => {
        try {
            const response = await axios.get('https://athixdev.xyz/aabb/contas_pagar.php?resumo=true');
            if (response.data) {
                const receitas = parseFloat(response.data.receitas ?? 0);
                const despesas = parseFloat(response.data.despesas ?? 0);
                const total = receitas - despesas;

                setResumo({ receitas, despesas, total });
                setGraficoData([
                    { name: 'Receitas', value: receitas },
                    { name: 'Despesas', value: despesas },
                ]);
            } else {
                console.error("Dados não retornados corretamente:", response);
            }
        } catch (error) {
            console.error('Erro ao buscar resumo:', error);
        }
    };

    fetchResumo();
}, []);


  return (
    <Box sx={{ padding: 3 }}>

       {/* Breadcrumbs para navegação */}
 <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Módulo Financeiro
        </Link>
        <Typography color="text.primary">Contas Bancárias</Typography>
      </Breadcrumbs>
      {/* Resumo de Balanço */}
      <Box>
            <Stack direction="row" spacing={3} alignItems="center">
                <Box>
                    <Typography variant="h6">Receitas</Typography>
                    <Typography color="green" variant="h5">R$ {resumo.receitas.toFixed(2)}</Typography>
                </Box>
                <Box>
                    <Typography variant="h6">Despesas</Typography>
                    <Typography color="red" variant="h5">R$ {resumo.despesas.toFixed(2)}</Typography>
                </Box>
                <Box>
                    <Typography variant="h6">Total do período</Typography>
                    <Typography color="primary" variant="h5">R$ {resumo.total.toFixed(2)}</Typography>
                </Box>
            </Stack>

            <ResponsiveContainer width="100%" height={200}>
                <BarChart data={graficoData}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <RechartTooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </Box>
    

      {/* Tabela de Contas Cadastradas */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
  <Toolbar>
    <Typography variant="h6">Histórico de Contas</Typography>
  </Toolbar>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Fornecedor</TableCell>
        <TableCell>Categoria</TableCell>
        <TableCell>Valor</TableCell>
        <TableCell>Data de Vencimento</TableCell>
        <TableCell>Data de Pagamento</TableCell>
        <TableCell>Data de Emissão</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Histórico</TableCell>
        <TableCell>Número Nota Fiscal</TableCell>
        <TableCell>Número do Pedido</TableCell>
        <TableCell>Parcelado</TableCell>
        <TableCell>Quantidade de Parcelas</TableCell>
        <TableCell>Ações</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
  {contas.map((conta) => (
    <TableRow key={conta.id}>
      <TableCell>{conta.id}</TableCell>
      <TableCell>{conta.Fornecedor}</TableCell>
      <TableCell>{conta.Categoria}</TableCell>
      <TableCell>{conta.Valor !== 'N/A' ? conta.Valor : '—'}</TableCell>
      <TableCell>{conta.DataVencimento !== 'N/A' ? conta.DataVencimento : '—'}</TableCell>
      <TableCell>{conta.DataPagamento !== 'N/A' ? conta.DataPagamento : '—'}</TableCell>
      <TableCell>{conta.DataEmissao !== 'N/A' ? conta.DataEmissao : '—'}</TableCell>
      <TableCell>{conta.Status}</TableCell>
      <TableCell>{conta.Histórico !== 'N/A' ? conta.Histórico : '—'}</TableCell>
      <TableCell>{conta.NumeroNotaFiscal !== 'N/A' ? conta.NumeroNotaFiscal : '—'}</TableCell>
      <TableCell>{conta.NumeroPedido !== 'N/A' ? conta.NumeroPedido : '—'}</TableCell>
      <TableCell>{conta.Parcelado}</TableCell>
      <TableCell>{conta.QuantidadeParcelas !== 'N/A' ? conta.QuantidadeParcelas : '—'}</TableCell>
      <TableCell>
        <IconButton color="primary" onClick={() => handleEdit(conta)}>
          <EditIcon />
        </IconButton>
        <IconButton color="error" onClick={() => handleDelete(conta.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>

  </Table>
</TableContainer>




      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
  <Paper
    sx={{
      width: '90%',
      maxWidth: 600,
      maxHeight: '90vh',
      overflow: 'auto',
      padding: 4,
      mx: 'auto',
      my: '5%',
      position: 'relative',
    }}
  >
    {/* Botão de Fechar (X) no canto superior direito */}
    <IconButton
      aria-label="close"
      onClick={() => setModalOpen(false)}
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon />
    </IconButton>
    <Typography variant="h6" mb={2}>
      Adicionar Conta
    </Typography>
    <Grid container spacing={2}>
      {/* Campo Fornecedor */}
      <Grid item xs={12}>
      <Autocomplete
  options={fornecedores} // Verifique se 'fornecedores' contém os dados esperados
  getOptionLabel={(option) => option.nome_razao_social} // Confirme que este campo existe nos dados retornados
  value={selectedFornecedor}
  onChange={(event, newValue) => setSelectedFornecedor(newValue)}
  renderInput={(params) => (
    <TextField {...params} label="Fornecedor" fullWidth />
  )}
/>

            </Grid>

      {/* Campo Categoria */}
      <Grid item xs={12}>
              <Autocomplete
                options={categorias} // Lista de categorias da API
                getOptionLabel={(option) => option.nome} // Define o campo 'nome' como o label
                value={selectedCategoria}
                onChange={(event, newValue) => setSelectedCategoria(newValue)} // Armazena o objeto da categoria selecionada
                renderInput={(params) => (
                  <TextField {...params} label="Categoria" fullWidth />
                )}
              />
            </Grid>


            <Grid item xs={12}>
              <Autocomplete
                options={centrosDeCusto}
                getOptionLabel={(option) => option.nome} // Define o campo 'nome' como o label
                value={selectedCentroCusto}
                onChange={(event, newValue) => setSelectedCentroCusto(newValue)} // Armazena o objeto do centro de custo selecionado
                renderInput={(params) => (
                  <TextField {...params} label="Centro de Custo" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12}>
  <Autocomplete
    options={contasBancarias}
    getOptionLabel={(option) => option.banco || ''} // Define 'banco' como o label principal para exibir o nome do banco
    value={selectedContaEmpresa}
    onChange={(event, newValue) => setSelectedContaEmpresa(newValue)}
    renderInput={(params) => (
      <TextField {...params} label="Conta Bancária/Conta Empresa" fullWidth />
    )}
  />
</Grid>



      {/* Campos de Data na Mesma Linha */}
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data de Emissão"
            value={dataEmissao}
            onChange={(newValue) => setDataEmissao(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data de Vencimento"
            value={dataVencimento}
            onChange={(newValue) => setDataVencimento(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data de Pagamento"
            value={dataPagamento}
            onChange={(newValue) => setDataPagamento(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>

      {/* Campo Status */}
     {/* Campo Status */}
<Grid item xs={12}>
  <TextField
    select
    fullWidth
    label="Status"
    value={status}
    onChange={(e) => setStatus(e.target.value)}
  >
    <MenuItem value="pendente">Pendente</MenuItem>
    <MenuItem value="pago">Pago</MenuItem>
    <MenuItem value="aguardando">Aguardando Aprovação</MenuItem>
    <MenuItem value="cancelado">Cancelado</MenuItem>
    <MenuItem value="processamento">Em Processamento</MenuItem>
    <MenuItem value="rejeitado">Rejeitado</MenuItem>
  </TextField>
</Grid>

      <Grid item xs={12}>
  <TextField
    fullWidth
    label="Número da Nota Fiscal"
    value={numeroNotaFiscal}
    onChange={(e) => setNumeroNotaFiscal(e.target.value)}
  />
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    label="Número do Pedido"
    value={numeroPedido}
    onChange={(e) => setNumeroPedido(e.target.value)}
  />
</Grid>
<Grid item xs={12}>
  <TextField
    label="Valor"
    fullWidth
    value={valor}
    onChange={(e) => setValor(e.target.value.replace(',', '.'))} // Substitui vírgula por ponto
    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Configurações de entrada numérica
  />
</Grid>

<Grid item xs={12}>
  <TextField
    select
    fullWidth
    label="Parcelado"
    value={parcelado}
    onChange={(e) => setParcelado(e.target.value)}
  >
    <MenuItem value="nao">Não</MenuItem>
    <MenuItem value="sim">Sim</MenuItem>
  </TextField>
</Grid>

{/* Campo Condicional para Quantidade de Parcelas */}
{parcelado === 'sim' && (
  <Grid item xs={12}>
    <TextField
      fullWidth
      label="Quantidade de Parcelas"
      value={quantidadeParcelas}
      onChange={(e) => setQuantidadeParcelas(e.target.value)}
      type="number" // Tipo numérico para permitir apenas números
      inputProps={{ min: 1 }} // Definir o mínimo como 1
    />
  </Grid>
)}



      {/* Botão Salvar */}
      <Grid item xs={12}>
        <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
          Salvar Conta
        </Button>
      </Grid>
    </Grid>
  </Paper>
</Modal>


      {/* Snackbar para Feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
