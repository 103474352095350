import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  useMediaQuery,
  Container,
  Divider,
  Paper,
  Avatar,
} from '@mui/material';
import {
  Category as CategoryIcon,
  Store as StoreIcon,
  Receipt as ReceiptIcon,
  AttachMoney as AttachMoneyIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  BusinessCenter as BusinessCenterIcon,
  TrendingUp as TrendingUpIcon,
  CreditCard as CreditCardIcon,
  AccountBalance as AccountBalanceIcon,
  Email as EmailIcon,
  InsertChart as InsertChartIcon,
  TaskAlt as TaskAltIcon,
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { LineChart, BarChart } from '@mui/x-charts';

const cardData = [
  { icon: <AccountBalanceIcon />, title: 'Saldo', value: 'R$ 50.000' },
  { icon: <TrendingUpIcon />, title: 'Receita', value: 'R$ 12.500' },
  { icon: <AttachMoneyIcon />, title: 'Despesas', value: 'R$ 8.000' },
  { icon: <EmailIcon />, title: 'E-mails Pendentes', value: '20' },
  { icon: <InsertChartIcon />, title: 'Projetos Ativos', value: '12' },
  { icon: <TaskAltIcon />, title: 'Tarefas Concluídas', value: '35' },
];

export default function Dashboard() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: { main: '#1E4A7B' },
          secondary: { main: '#546E7A' },
          background: { default: '#F3F6F9', paper: '#FFFFFF' },
        },
        typography: { fontFamily: 'Poppins, sans-serif', fontSize: 14 },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 12,
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                borderRadius: 8,
              },
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: 4,
          background: theme.palette.background.default,
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                marginBottom: 4,
              }}
            >
              Dashboard
            </Typography>
          </motion.div>

          {/* Cards de Resumo */}
          <Grid container spacing={4}>
            {cardData.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card elevation={3}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Avatar
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          width: 56,
                          height: 56,
                          margin: 'auto',
                          marginBottom: 2,
                        }}
                      >
                        {card.icon}
                      </Avatar>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {card.title}
                      </Typography>
                      <Typography variant="h5" color="secondary">
                        {card.value}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>

          {/* Gráficos */}
          <Grid container spacing={4} sx={{ marginTop: 4 }}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 4, borderRadius: 8 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Vendas Mensais
                </Typography>
                <LineChart
                  width={500}
                  height={300}
                  series={[{ data: [5, 15, 25, 35, 45] }]}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ padding: 4, borderRadius: 8 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Desempenho Financeiro
                </Typography>
                <BarChart
                  width={500}
                  height={300}
                  series={[{ data: [1000, 1500, 2500, 3000, 4000] }]}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
