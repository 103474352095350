import * as React from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Snackbar,
  IconButton,
  Tooltip,
  SvgIcon,
  Link,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

const defaultTheme = createTheme({
  palette: {
    primary: { main: '#2c3e50' },
    secondary: { main: '#f39c12' },
    background: { default: '#ecf0f1' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h5: {
      fontWeight: 700,
      color: '#34495e',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
        },
      },
    },
  },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Desenvolvido por © '}
      <a
        href="https://athix.com.br/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit' }}
      >
        ATHIX
      </a>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

function TodoistIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm3 10.5a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Zm0-4a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Zm0-4a1 1 0 1 0 0 2h12a1 1 0 0 0 0-2H6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function SignInSide() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [openModal, setOpenModal] = React.useState(false);
  const [forgotEmail, setForgotEmail] = React.useState('');

  React.useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';

    if (savedRememberMe) {
      setEmail(savedEmail || '');
      setPassword(savedPassword || '');
      setRememberMe(true);
    }
  }, []);

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleRememberMe = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);

    if (!isChecked) {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/login.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailOuLogin: email, senha: password }),
      });

      const data = await response.json();

      if (response.ok && data.token) {
        localStorage.setItem('token', data.token);
        if (rememberMe) {
          localStorage.setItem('email', email);
          localStorage.setItem('password', password);
          localStorage.setItem('rememberMe', 'true');
        }
        setSnackbarMessage('🎉 Login realizado com sucesso! Bem-vindo(a) ao sistema.');
        setSnackbarSeverity('success');
        navigate('/dashboard');
      } else {
        setSnackbarMessage(data.message || '❌ Falha no login. Por favor, verifique suas credenciais.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('❌ Erro ao conectar com o servidor. Tente novamente mais tarde.');
      setSnackbarSeverity('error');
    }
    setOpenSnackbar(true);
  };

  const handleForgotPassword = () => setOpenModal(true);

  const handleModalClose = () => setOpenModal(false);

  const handleForgotPasswordSubmit = async () => {
    if (!forgotEmail) {
      setSnackbarMessage('Por favor, insira um e-mail ou nome de usuário.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/esqueceu-senha.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: forgotEmail }),
      });
  
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage('📧 Um link para redefinir sua senha foi enviado para o e-mail informado.');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(data.message || '❌ Erro ao enviar o e-mail. Tente novamente.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('❌ Erro ao conectar com o servidor. Tente novamente mais tarde.');
      setSnackbarSeverity('error');
    }
  
    setOpenSnackbar(true);
    setOpenModal(false);
  };
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{
          height: '100vh',
          backgroundImage:
            'url(https://img.freepik.com/fotos-gratis/vista-superior-da-planta-de-arquitetura_23-2148431996.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={10}
          square
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(6px)',
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              p: 5,
              width: '100%',
              maxWidth: '450px',
              borderRadius: '12px',
              boxShadow: '0px 15px 40px rgba(0,0,0,0.15)',
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          >
            <img
              alt="Logo"
              src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
              style={{ width: '150px', marginBottom: '20px' }}
            />

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="E-mail ou Usuário"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="password"
                label="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox checked={rememberMe} onChange={handleRememberMe} />}
                label="Lembrar-me"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
                Entrar
              </Button>
              <Link
                href="#"
                variant="body2"
                onClick={handleForgotPassword}
                sx={{ mt: 2 }}
              >
                Esqueci minha senha
              </Link>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Modal
  open={openModal}
  onClose={handleModalClose}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{ timeout: 500 }}
>
  <Fade in={openModal}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
      }}
    >
      <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
        Recuperação de Senha
      </Typography>
      <TextField
        fullWidth
        label="E-mail"
        value={forgotEmail}
        onChange={(e) => setForgotEmail(e.target.value)}
        sx={{ mb: 3 }}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={handleForgotPasswordSubmit}
        sx={{ mb: 2 }}
      >
        Enviar Link
      </Button>
      <Button
        fullWidth
        onClick={handleModalClose}
        sx={{ color: 'red' }}
      >
        Fechar
      </Button>
    </Box>
  </Fade>
</Modal>

    </ThemeProvider>
  );
}
