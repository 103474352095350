import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Usuarios from './pages/Usuarios'; // Importação da página de Usuários
import Empresas from './pages/Empresas'; // Importação da página de Usuários
import Certificados from './pages/Certificados'; // Importação da página de Usuários
import ConfiguracaoEmail from './pages/ConfiguracaoEmail'; // Importação da página de Email
import Integracoes from './pages/Integracoes'; // Importação da página de Usuários
import ModuloFinanceiro from './components/Financeiro/ModuloFinanceiro'; // Importação correta do ModuloFinanceiro
import CadastroCliente from './pages/CadastroCliente';
import EditarCliente from './pages/EditarCliente';
import Funcionarios from './pages/Funcionarios';
import CadastroFuncionarios from './pages/CadastroFuncionarios';
import Fornecedores from './pages/Fornecedores';
import CadastroFornecedor from './pages/CadastroFornecedor';
import Produtos from './pages/Produtos';
import CadastroProduto from './pages/CadastroProdutos';
import Servicos from './pages/Servicos';
import CadastroServico from './pages/CadastroServico';
import Projetos from './pages/Projetos';
import ContasPagar from './pages/ContasPagar';
import Clientes from './pages/Clientes';
import CategoriasTransacoes from './pages/CategoriasTransacoes';
import CentroCusto from './pages/CentroCusto';
import ContaBancaria from './pages/ContaBancaria';
import ContasReceber from './pages/ContasReceber.js';
import FluxoCaixa from './pages/FluxoCaixa.js';
import Cadastros from './pages/Cadastros.js';
import Configuracoes from './pages/Configuracoes.js';
import MeuPerfil from './components/MeuPerfil.js';
import TrocarSenha from './components/TrocarSenha.js';






import ProtectedRoute from './ProtectedRoute';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
function ProtectedLayout({ children, darkMode, setDarkMode }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <>
      <Header
        onToggleSidebar={toggleSidebar}
        sidebarOpen={sidebarOpen}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <Sidebar
        open={sidebarOpen}
        onToggle={toggleSidebar}
        darkMode={darkMode}
      />
      <div
        style={{
          marginLeft: sidebarOpen ? 240 : 72,
          marginTop: 64,
          padding: '16px',
          transition: 'margin-left 0.3s',
        }}
      >
        {children}
      </div>
      <Footer />
    </>
  );
}

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/trocar-senha" element={<TrocarSenha />} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Dashboard />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/usuarios" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Usuarios />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
  path="/cadastros"
  element={
    <ProtectedRoute>
      <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
        <Cadastros />
      </ProtectedLayout>
    </ProtectedRoute>
  }
/>


<Route
  path="/meu-perfil"
  element={
    <ProtectedRoute>
      <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
        <MeuPerfil />
      </ProtectedLayout>
    </ProtectedRoute>
  }
/>





<Route
  path="/configuracoes"
  element={
    <ProtectedRoute>
      <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
        <Configuracoes />
      </ProtectedLayout>
    </ProtectedRoute>
  }
/>





<Route
            path="/cadastro-clientes" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CadastroCliente />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/contas-pagar" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <ContasPagar />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/contas-receber" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <ContasReceber />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />



<Route
            path="/fluxo-caixa" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <FluxoCaixa />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/funcionarios" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Funcionarios />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

      <Route
            path="/cadastro-produtos" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CadastroProduto />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/CategoriasTransacoes" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CategoriasTransacoes />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/conta-bancaria" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <ContaBancaria />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />



<Route
            path="/centro-custo" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CentroCusto />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/cadastro-servicos" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CadastroServico />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/servicos" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Servicos />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/projetos" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Projetos />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/produtos" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Produtos />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />



<Route
            path="/tabelaClientes/edit/:id" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <EditarCliente  />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/cadastro-fornecedor" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CadastroFornecedor  />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/fornecedores" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Fornecedores  />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/CadastroFuncionarios" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <CadastroFuncionarios  />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


          <Route
            path="/clientes" // Nova rota para a página de Usuários
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Clientes />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/Empresas" // Nova rota para a página de Empresas
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Empresas />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


        <Route
            path="/Certificados" // Nova rota para a página de Certificado Digital
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Certificados />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


          <Route
            path="/ConfiguracaoEmail" // Nova rota para a página de ConfiguracaoEmail
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <ConfiguracaoEmail />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/modulo-financeiro"
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <ModuloFinanceiro />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/Integracoes" // Nova rota para a página de ConfiguracaoEmail
            element={
              <ProtectedRoute>
                <ProtectedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
                  <Integracoes />
                </ProtectedLayout>
              </ProtectedRoute>
            }
          />
          
        </Routes>

        


        


        
      </Router>
    </ThemeProvider>
  );
}

export default App;
