import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  Divider,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Collapse,
  useTheme,
  Box,
} from '@mui/material';
import {
  Home as HomeIcon,
  AccountBalance as AccountBalanceIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  People as PeopleIcon,
  ExpandLess,
  ExpandMore,
  Group as GroupIcon,
  ShoppingCart as ShoppingCartIcon,
  Inventory as InventoryIcon,
  AttachMoney as AttachMoneyIcon,
  Receipt as ReceiptIcon,
  Build as BuildIcon,
  Report as ReportIcon,
  Api as ApiIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Sidebar({ open, onToggle }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const [perfilId, setPerfilId] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [comprasOpen, setComprasOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/me.php`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log('Perfil do usuário:', data);
        setPerfilId(Number(data.perfil_id));
      } catch (error) {
        console.error('Erro ao buscar perfil do usuário:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogoutDialogOpen = () => setLogoutDialogOpen(true);
  const handleLogoutDialogClose = () => setLogoutDialogOpen(false);
  const toggleSettings = () => setSettingsOpen(!settingsOpen);
  const toggleCompras = () => setComprasOpen(!comprasOpen);

  const sidebarItems = [
    { text: 'Início', icon: <HomeIcon />, route: '/dashboard' },
    { text: 'Cadastros', icon: <PeopleIcon />, route: '/cadastros' },
    { text: 'Financeiro', icon: <AccountBalanceIcon />, route: '/modulo-financeiro' },
    {
      text: 'Compras',
      icon: <ShoppingCartIcon />,
      hasSubmenu: true,
      submenu: [
        { text: 'Cotação de Compra', route: '/cotacao-compra' },
        { text: 'Entrada de Produtos', route: '/entrada-produtos' },
        { text: 'Pedido de Compra', route: '/pedido-compra' },
      ],
    },
    { text: 'Estoque', icon: <InventoryIcon />, route: '/estoque' },
    { text: 'Vendas', icon: <AttachMoneyIcon />, route: '/vendas' },
    { text: 'Ordem de Serviço', icon: <BuildIcon />, route: '/ordem-servico' },
    { text: 'Relatórios', icon: <ReportIcon />, route: '/relatorios' },
  ];

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        sx={{
          width: open ? 260 : 80,
          transition: 'width 0.3s',
          '& .MuiDrawer-paper': {
            width: open ? 260 : 80,
            overflowX: 'hidden',
            background: isDarkMode
              ? 'linear-gradient(145deg, #2e3440, #434c5e)'
              : 'linear-gradient(145deg, #f5f7fa, #e5e9f0)',
            color: isDarkMode ? '#eceff4' : '#2e3440',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        {/* Top Section */}
        <Box>
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 120,
              padding: '10px 0',
              transition: 'all 0.3s',
            }}
          >
            <IconButton onClick={onToggle} sx={{ color: '#4a90e2', mb: 2 }}>
              <MenuIcon />
            </IconButton>
            <img
              src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
              alt="Logo"
              style={{
                width: open ? '160px' : '50px',
                height: 'auto',
                transition: 'width 0.3s',
                marginTop: 10,
                marginBottom: 10,
                borderRadius: '5px',
              }}
            />
          </Toolbar>
          <Divider sx={{ backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' }} />

          {/* Main Items */}
          <List>
            {sidebarItems.map((item, index) => (
              <React.Fragment key={index}>
                {!item.hasSubmenu ? (
                  <Tooltip title={!open ? item.text : ''} placement="right">
                    <ListItem
                      button
                      onClick={() => navigate(item.route)}
                      sx={{
                        '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                        borderRadius: '10px',
                        margin: '8px',
                      }}
                    >
                      <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                        {item.icon}
                      </ListItemIcon>
                      {open && <ListItemText primary={item.text} />}
                    </ListItem>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip title={!open ? item.text : ''} placement="right">
                      <ListItem
                        button
                        onClick={toggleCompras}
                        sx={{
                          '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                          borderRadius: '10px',
                          margin: '8px',
                        }}
                      >
                        <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                          {item.icon}
                        </ListItemIcon>
                        {open && <ListItemText primary={item.text} />}
                        {open && (comprasOpen ? <ExpandLess /> : <ExpandMore />)}
                      </ListItem>
                    </Tooltip>
                    <Collapse in={comprasOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.submenu.map((subItem, subIndex) => (
                          <Tooltip
                            title={!open ? subItem.text : ''}
                            placement="right"
                            key={subIndex}
                          >
                            <ListItem
                              button
                              onClick={() => navigate(subItem.route)}
                              sx={{
                                '&:hover': {
                                  backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9',
                                },
                                borderRadius: '10px',
                                margin: '8px',
                                paddingLeft: open ? 4 : 2,
                              }}
                            >
                              <ListItemText primary={open ? subItem.text : ''} />
                            </ListItem>
                          </Tooltip>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
              </React.Fragment>
            ))}

            {/* Configurações com Submenu */}
            {perfilId === 1 && perfilId !== null && (
              <>
                <Tooltip title={!open ? 'Configurações' : ''} placement="right">
                  <ListItem
                    button
                    onClick={toggleSettings}
                    sx={{
                      '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                      borderRadius: '10px',
                      margin: '8px',
                    }}
                  >
                    <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                      <SettingsIcon />
                    </ListItemIcon>
                    {open && <ListItemText primary="Configurações" />}
                    {open && (settingsOpen ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                </Tooltip>
                <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Tooltip title={!open ? 'Usuários' : ''} placement="right">
                      <ListItem
                        button
                        onClick={() => navigate('/usuarios')}
                        sx={{
                          '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                          borderRadius: '10px',
                          margin: '8px',
                          paddingLeft: open ? 4 : 2,
                        }}
                      >
                        <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                          <GroupIcon />
                        </ListItemIcon>
                        {open && <ListItemText primary="Usuários" />}
                      </ListItem>
                    </Tooltip>
                    <Tooltip title={!open ? 'API' : ''} placement="right">
                      <ListItem
                        button
                        onClick={() => navigate('/configuracoes/api')}
                        sx={{
                          '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                          borderRadius: '10px',
                          margin: '8px',
                          paddingLeft: open ? 4 : 2,
                        }}
                      >
                        <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                          <ApiIcon />
                        </ListItemIcon>
                        {open && <ListItemText primary="API" />}
                      </ListItem>
                    </Tooltip>
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </Box>

        {/* Footer Section */}
        <Box sx={{ padding: '16px', textAlign: 'center' }}>
          <Tooltip title="Sair" placement="right">
            <ListItem
              button
              onClick={handleLogoutDialogOpen}
              sx={{
                '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                borderRadius: '10px',
              }}
            >
              <ListItemIcon sx={{ color: isDarkMode ? '#d08770' : '#bf616a' }}>
                <LogoutIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Sair" />}
            </ListItem>
          </Tooltip>
        </Box>
      </Drawer>

      {/* Logout Dialog */}
      <Dialog open={logoutDialogOpen} onClose={handleLogoutDialogClose}>
        <DialogTitle>Deseja realmente sair?</DialogTitle>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose}>Cancelar</Button>
          <Button onClick={handleLogout} color="error">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
