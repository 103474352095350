import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function TrocarSenha() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Captura o token do link

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleSubmit = async () => {
    if (!newPassword || !confirmPassword) {
      setSnackbarMessage('Todos os campos são obrigatórios.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setSnackbarMessage('As senhas não coincidem.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/trocar-senha.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }),
      });

      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage('Senha atualizada com sucesso! Você será redirecionado para o login.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setTimeout(() => navigate('/'), 2000);
      } else {
        setSnackbarMessage(data.message || 'Erro ao atualizar a senha.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    } catch {
      setSnackbarMessage('Erro ao conectar com o servidor. Tente novamente mais tarde.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
        <Box textAlign="center" sx={{ mb: 4 }}>
          <img
            alt="Logo"
            src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
            style={{ width: '100px', marginBottom: '20px' }}
          />
        </Box>
        <Typography component="h1" variant="h6" textAlign="center" sx={{ mb: 2 }}>
          Redefinir Senha
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          type="password"
          label="Nova Senha"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          type="password"
          label="Confirmar Nova Senha"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          Atualizar Senha
        </Button>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
