import React from 'react';
import { Box, Typography, Link, Fab, useTheme } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export default function Footer() {
  const theme = useTheme(); // Acessa o tema atual (claro/escuro)
  const isDarkMode = theme.palette.mode === 'dark'; // Verifica se é modo escuro

  return (
    <>
      {/* Apenas o nome ATHIX à direita */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 8,
          right: 16,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          <Link
            href="https://athix.com.br"
            target="_blank"
            rel="noopener"
            sx={{
              color: isDarkMode ? '#6cc24a' : '#4a90e2',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            ATHIX
          </Link>
        </Typography>
      </Box>

      {/* Ícone flutuante de calendário */}

    </>
  );
}
