import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Grid, Typography, Paper, Modal,
  Snackbar, Alert, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, MenuItem, Breadcrumbs, Link, Autocomplete, Toolbar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function ContasAReceber() {
  const navigate = useNavigate();
  const [contas, setContas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [valor, setValor] = useState('');
  const [dataVencimento, setDataVencimento] = useState(null);
  const [dataRecebimento, setDataRecebimento] = useState(null);
  const [status, setStatus] = useState('pendente');
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [parcelado, setParcelado] = useState('nao');
  const [quantidadeParcelas, setQuantidadeParcelas] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [resumo, setResumo] = useState({ receitas: 0, despesas: 0, total: 0 });
  const [graficoData, setGraficoData] = useState([]);

  const API_BASE_URL = 'https://athixdev.xyz/aabb';

  const fetchContas = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/contas_receber.php`);
      setContas(response.data);
    } catch (error) {
      console.error("Erro ao buscar contas a receber:", error);
    }
  };

  const fetchClientes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/clientes.php`);
      setClientes(response.data);
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  const fetchCategorias = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categorias_transacoes.php`);
      setCategorias(response.data);
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  const fetchResumo = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/contas_receber.php?resumo=true`);
      if (response.data) {
        const receitas = parseFloat(response.data.receitas ?? 0);
        const despesas = parseFloat(response.data.despesas ?? 0);
        const total = receitas - despesas;

        setResumo({ receitas, despesas, total });
        setGraficoData([
          { name: 'Receitas', value: receitas },
          { name: 'Despesas', value: despesas },
        ]);
      }
    } catch (error) {
      console.error('Erro ao buscar resumo:', error);
    }
  };

  const handleSave = async () => {
    const payload = {
      cliente_id: selectedCliente?.id || null,
      categoria_id: selectedCategoria?.id || null,
      valor: parseFloat(valor.replace(/[^0-9.-]+/g, "")) || 0,
      data_vencimento: dataVencimento ? dataVencimento.toISOString().split('T')[0] : null,
      data_recebimento: dataRecebimento ? dataRecebimento.toISOString().split('T')[0] : null,
      status,
      parcelado,
      quantidade_parcelas: parcelado === 'sim' ? parseInt(quantidadeParcelas, 10) : null
    };

    try {
      if (editingId) {
        await axios.put(`${API_BASE_URL}/contas_receber.php`, { ...payload, id: editingId });
        setSnackbarMessage('Conta a receber atualizada com sucesso!');
      } else {
        await axios.post(`${API_BASE_URL}/contas_receber.php`, payload);
        setSnackbarMessage('Conta a receber criada com sucesso!');
      }
      setSnackbarOpen(true);
      setModalOpen(false);
      fetchContas();
      resetForm();
    } catch (error) {
      console.error("Erro ao salvar conta a receber:", error);
      setSnackbarMessage('Erro ao salvar conta a receber.');
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (conta) => {
    setEditingId(conta.id);
    setSelectedCliente(clientes.find((c) => c.id === conta.cliente_id) || null);
    setSelectedCategoria(categorias.find((c) => c.id === conta.categoria_id) || null);
    setValor(conta.valor);
    setDataVencimento(conta.data_vencimento ? new Date(conta.data_vencimento) : null);
    setDataRecebimento(conta.data_recebimento ? new Date(conta.data_recebimento) : null);
    setStatus(conta.status);
    setParcelado(conta.parcelado);
    setQuantidadeParcelas(conta.quantidade_parcelas || '');
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/contas_receber.php`, { data: { id } });
      setSnackbarMessage('Conta a receber removida com sucesso!');
      setSnackbarOpen(true);
      fetchContas();
    } catch (error) {
      console.error("Erro ao excluir conta a receber:", error);
    }
  };

  const resetForm = () => {
    setEditingId(null);
    setSelectedCliente(null);
    setSelectedCategoria(null);
    setValor('');
    setDataVencimento(null);
    setDataRecebimento(null);
    setStatus('pendente');
    setParcelado('nao');
    setQuantidadeParcelas('');
  };

  useEffect(() => {
    fetchContas();
    fetchClientes();
    fetchCategorias();
    fetchResumo();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Layout da página, incluindo Breadcrumbs, Tabela e Modal */}
      {/* O código aqui continua conforme necessário */}
    </Box>
  );
}
