import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Usuarios() {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Fetch de usuários
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_PHP_URL}/user/usuarios.php`)
      .then((res) => res.json())
      .then((data) => {
        // Ordena os usuários por data_criacao (mais recentes primeiro)
        const sortedData = data.sort((a, b) => new Date(b.data_criacao) - new Date(a.data_criacao));
        setUsers(sortedData);
      });
  }, []);
  

  // Fetch de empresas
  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_PHP_URL}/user/usuarios.php?empresas=true`);
        const data = await response.json();
        setEmpresas(data);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };

    fetchEmpresas();
  }, []);

  const handleOpenModal = (user = null) => {
    setCurrentUser(user || { nome: '', email: '', login: '', senha: '', perfil_id: '', empresa_id: '' });
    setIsEditing(!!user);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentUser(null);
    setModalOpen(false);
  };

  const handleSaveUser = () => {
    const method = isEditing ? 'PUT' : 'POST';
    fetch(`${process.env.REACT_APP_API_PHP_URL}/user/usuarios.php`, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentUser),
    })
      .then((res) => res.json())
      .then(() => {
        fetch(`${process.env.REACT_APP_API_PHP_URL}/user/usuarios.php`)
          .then((res) => res.json())
          .then((data) => setUsers(data));
        handleCloseModal();
      });
  };

  const handleDeleteUser = (id) => {
    fetch(`${process.env.REACT_APP_API_PHP_URL}/user/usuarios.php`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    })
      .then((res) => res.json())
      .then(() => {
        setUsers(users.filter((user) => user.id !== id));
      });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'nome', headerName: 'Nome', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'login', headerName: 'Login', width: 150 },
    { field: 'perfil_id', headerName: 'Perfil', width: 200 },
    { field: 'empresa_nome', headerName: 'Empresa', width: 200 },
    { field: 'data_criacao', headerName: 'Data Criação', width: 150 },
    { field: 'ultimo_acesso', headerName: 'Último Acesso', width: 150 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpenModal(params.row)}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => handleDeleteUser(params.row.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        Usuários
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpenModal()} sx={{ marginBottom: 2 }}>
        Adicionar Usuário
      </Button>
      <DataGrid rows={users} columns={columns} pageSize={5} autoHeight />

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{isEditing ? 'Editar Usuário' : 'Adicionar Usuário'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                fullWidth
                value={currentUser?.nome || ''}
                onChange={(e) => setCurrentUser({ ...currentUser, nome: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                value={currentUser?.email || ''}
                onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Login"
                fullWidth
                value={currentUser?.login || ''}
                onChange={(e) => setCurrentUser({ ...currentUser, login: e.target.value })}
              />
            </Grid>
            {!isEditing && (
              <Grid item xs={12}>
                <TextField
                  label="Senha"
                  type="password"
                  fullWidth
                  value={currentUser?.senha || ''}
                  onChange={(e) => setCurrentUser({ ...currentUser, senha: e.target.value })}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                fullWidth
                value={currentUser?.perfil_id || ''}
                onChange={(e) => setCurrentUser({ ...currentUser, perfil_id: e.target.value })}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Selecione o Perfil
                </MenuItem>
                {['SuperAdmin', 'Administrador da empresa', 'Usuário da empresa'].map((perfil, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {perfil}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                value={currentUser?.empresa_id || ''}
                onChange={(e) => setCurrentUser({ ...currentUser, empresa_id: e.target.value })}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Selecione a Empresa
                </MenuItem>
                {empresas.map((empresa) => (
                  <MenuItem key={empresa.id} value={empresa.id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleSaveUser} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
