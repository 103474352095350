import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  useMediaQuery,
  Container,
  Divider,
} from '@mui/material';
import {
  Security as SecurityIcon,
  Email as EmailIcon,
  Business as BusinessIcon,
  IntegrationInstructions as IntegrationIcon,
  Tune as TuneIcon,
  People as PeopleIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const configuracoesModules = [
  {
    title: 'Certificado Digital',
    description: 'Gerencie os certificados digitais da empresa.',
    icon: <SecurityIcon fontSize="large" />,
    link: '/certificados',
  },
  {
    title: 'E-mail',
    description: 'Configure as informações de e-mail da empresa.',
    icon: <EmailIcon fontSize="large" />,
    link: '/configuracaoemail',
  },
  {
    title: 'Empresas',
    description: 'Gerencie as informações das empresas cadastradas.',
    icon: <BusinessIcon fontSize="large" />,
    link: '/empresas',
  },
  {
    title: 'Integrados',
    description: 'Configure integrações com sistemas externos.',
    icon: <IntegrationIcon fontSize="large" />,
    link: '/integracoes',
  },
  {
    title: 'Parâmetros',
    description: 'Defina os parâmetros globais do sistema.',
    icon: <TuneIcon fontSize="large" />,
    link: '#', // Sem link no momento
  },
  {
    title: 'Usuários',
    description: 'Gerencie os usuários do sistema.',
    icon: <PeopleIcon fontSize="large" />,
    link: '/usuarios',
  },
  {
    title: 'WhatsApp',
    description: 'Configure o WhatsApp para comunicação com clientes.',
    icon: <WhatsAppIcon fontSize="large" />,
    link: '#', // Sem link no momento
  },
];

export default function Configuracoes() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: { main: '#2E3B55' },
          secondary: { main: '#546E7A' },
          background: { default: '#F9FAFB', paper: '#FFFFFF' },
        },
        typography: { fontFamily: 'Poppins, sans-serif', fontSize: 14 },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                borderRadius: 8,
              },
            },
          },
        },
      }),
    []
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: isSmallScreen ? 2 : 4,
          minHeight: '100vh',
          background: theme.palette.background.default,
        }}
      >
        {/* Header */}
        <Container maxWidth="lg" sx={{ textAlign: 'center', marginBottom: 4 }}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              Configurações
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.secondary.main, marginTop: 1 }}
            >
              Gerencie todas as configurações do sistema.
            </Typography>
          </motion.div>
        </Container>

        <Divider sx={{ marginY: 4 }} />

        {/* Configuracao Modules */}
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center">
            {configuracoesModules.map((module, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.6 }}
                >
                  <Card
                    sx={{
                      borderRadius: 3,
                      boxShadow: 4,
                      backgroundColor: theme.palette.background.paper,
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-10px)',
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardContent sx={{ textAlign: 'center', padding: 4 }}>
                      <IconButton
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: '#FFF',
                          marginBottom: 2,
                          '&:hover': { backgroundColor: theme.palette.primary.dark },
                        }}
                      >
                        {module.icon}
                      </IconButton>
                      <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 1 }}>
                        {module.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.secondary.main, marginBottom: 2 }}
                      >
                        {module.description}
                      </Typography>
                      <Button
                        component={Link}
                        to={module.link}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!module.link || module.link === '#'} // Desabilita botão sem link
                      >
                        Acessar
                      </Button>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
